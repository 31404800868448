import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Note from "../pages/Authentication/Note";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import Message from "../pages/Messages/Message";
import Finance from "../pages/Finance/Finance";
import Profile from "../pages/User/Profile";
import PublicChannel from "../pages/Public/PublicChannel";
import PreviewChannel from "../pages/Public/PreviewChannel";
import Api from "../pages/Apis/Api";
import NotFound from "../pages/NotFound/NotFound";

const authProtectedRoutes = [
	// { path: "/dashboard", component: <Dashboard /> },
	{ path: "/messages", name:"Messages", component: <Message /> },
	{ path: "/profile", name:"Profile", component: <Profile /> },
	{ path: "/finance/*", name:"Finance", component: <Finance /> },
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: <Navigate to="/important-note" /> },
	{ path: "*", component: <NotFound /> },
];

const publicRoutes = [
	{ path: "/logout",  name:"Logout", component: <Logout /> },
	{ path: "/login", Name:"Login" ,component: <Login /> },
	{ path: "/forgot-password", name:"Forget Password",component: <ForgetPwd /> },
	{ path: "/register", name:"Register", component: <Register /> },
	{ path: "/lock-screen", name:"Lock Screen", component: <AuthLockScreen /> },
	{ path: "/important-note", name:"Important Note",component: <Note /> },
	{ path: "/swagger-apis", name:"Swagger Apis", component: <Api /> },
	{ path: "/channel/:channelName", name:"Public Channel", component: <PublicChannel /> },
	{ path: "/channel-preview/:channelName", name:"Preview Channel", component: <PreviewChannel /> },
	{ path: "*", component: <NotFound /> },
];

export { authProtectedRoutes, publicRoutes };
