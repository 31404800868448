import React, { Component } from "react";
import { Row, Col, Container, Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import timer from "../../../assets/images/timer.png";
import arrow from "../../../assets/images/arrow.png";
import btc from "../../../assets/images/btc.png";
import Modal from "../../../components/model/model"; 
import withRouter from "../../../components/Common/withRouter";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import cancel from "../../../assets/images/cancel.png";
import { escrowStatusUpdateRequest } from "../../../store/actions";

class EscrowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTerms: true,
      escrow: [],
    };
  }
  componentDidMount() {
    const { user } = this.props;
    const currentEscrowId = this.props.router.location.pathname.split("/").pop();
    if (currentEscrowId) {
      const escrowData = user?.identity?.escrows?.find((escrow) => escrow.id == currentEscrowId);
      if(escrowData){
        const status = escrowData.status == "pending" ? true : false;
        this.setState({
          escrow: escrowData,
          showTerms: status
        });
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const currentEscrowId = this.props.router.location.pathname.split("/").pop();
    const previousEscrowId = prevProps.router.location.pathname.split("/").pop();
    if (currentEscrowId !== previousEscrowId) {
      const escrowData = user?.identity?.escrows?.find((escrow) => escrow.id == currentEscrowId);
      if(escrowData){
        const status = escrowData.status == "pending" ? true : false;
        this.setState({
          escrow: escrowData,
          showTerms: status
        });
      }
    }
  }
  handleUnderstandClick = () => {
    this.setState({ showTerms: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.onMenuItemClick("/finance/freezed-escrow");
  };
  downloadAttachment = async () => {
    const { escrow } = this.state;
    const attachmentUrl = escrow.attachment;
    try {
      const response = await fetch(attachmentUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = attachmentUrl.split('/').pop();
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); 
      setTimeout(() => {
        toast.success('Attachment downloaded successfully.');
      }, 1000);
    } catch (error) {
      toast.success('Error in download Attachment, please try again.');
    }
  };  
  handleEscrowUpdate = (status) => {
    const { escrow } = this.state;
    const { escrowStatusUpdateRequest } = this.props;
    if (!escrow) return;
    escrowStatusUpdateRequest(escrow.id, status,null,null,null,(escrowId) => {
      if (escrowId) {
        this.props.onMenuItemClick(`/finance/freezed-escrow/${escrowId}`);
      }}
    );
  };
  render() {
    const { escrow } = this.state;
    const {user} = this.props;
    const termsParagraphs = [
      "Please keep a few things in mind:",
      "Escrow Protection is in place for fixed-price jobs. Before you start the project, you and the client must agree to requirements, budget, and milestones. Resonance charges the client at the beginning of the project, and the money for a milestone is deposited in escrow.",
      "Escrow funded payments are released when the client approves work. When milestones are completed, the client can either approve work and release payment or request modifications to the work. Clients can also request that you approve the return of funds held in escrow.",
      "Resonance offers mediation services. If you do the work and the client refuses to pay, Resonance can help mediate the dispute.",
      "Please choose fixed-price jobs carefully. Only funds deposited for an active milestone are covered by Escrow Protection."
    ];
    return (
      <React.Fragment>
        <div className="page-content p-0">
          {Object.keys(escrow).length > 0 ? 
          <>
            {this.state.showTerms ? (
                <Modal
                title="Terms and Conditions"
                paragraphs={termsParagraphs}
                buttonText="I UNDERSTAND"
                onButtonClick={this.handleUnderstandClick}
              />
            ) : (
              <>
                {escrow.status == "pending" ? (
                  <Container fluid={true}>
                  <div className="crypto-content">
                    <h1 className="crypto-heading m-0">
                      <Link to="/finance/new-escrow" className="text-muted">
                        <img src={arrow} alt="Arrow" />
                      </Link>
                      <span className="mx-2">ESCROW</span>
                      <img src={timer} alt="Timer" />
                    </h1>
                  </div>
                    <Row>
                      <Col lg={12}>
                        <div className="escrow-details">
                          <div className="detail-item">
                            <span className="label">Seller:</span>
                            <span className="value">{escrow.sellerIdentity}</span>
                          </div>
                          <div className="detail-item">
                            <span className="label">Date:</span>
                            <span className="value">  {new Date(escrow.date).toLocaleDateString()}
                            </span>
                          </div>
                          <div className="detail-item">
                            <span className="label">Time Duration:</span>
                            <span className="value">{escrow.timeDuration} minute</span>
                          </div>
                          <div className="detail-item">
                            <span className="label">Type:</span>
                            <span className="value">{escrow.blockchain}
                            <img src={btc} alt="Timer" />
                            </span>
                          </div>
                          <div className="detail-item pb-1">
                            <span className="label">Service Fee:</span>
                            <span className="value">3%</span>
                          </div>
                          <div className="detail-item pb-1">
                            <span className="label">Attachments:</span>
                            <Button className="download-btn" size="sm" onClick={this.downloadAttachment}>
                                Download
                                <i className="fa fa-download"></i>
                            </Button>
                          </div>
                          <div className="detail-item pb-0">
                            <span className="label">Description:</span>
                          </div>
                          <div className="detail-item">
                            <span className="discription-cls">
                            {escrow.description}
                            </span>
                          </div>
                          <div className="detail-item mt-3">
                            <span className="label amount-cls">Amount:</span>
                            <span className="value amount-cls">{Number(escrow.amount).toFixed(2)} {escrow.coin.toUpperCase()}</span>
                          </div>
                          {escrow.sellerIdentity === user.identity.address && (
                            <div className="actions text-center">
                            <Button className="me-2 reject-btn" onClick={() => this.handleEscrowUpdate('rejected')}>
                                REJECT
                              </Button>
                              <Button className="btn cryto-btn" onClick={() => this.handleEscrowUpdate('accepted')}>
                                ACCEPT
                              </Button>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  ):<>
                   <Container>
                    <Row className="">
                      <div className="cancel-point">
                      <div className="">
                        <Card>
                          <CardBody className="cancel-cls">
                            <p>
                            <img src={cancel} alt="cancel"/>
                            </p>
                            <h2>Escrow is not in pending.</h2>
                          </CardBody>
                        </Card>
                      </div>
                      </div>
                    </Row>
                  </Container>
                </>
                }
              </>
            )}
            </>:
          <>
            <Container>
              <Row className="">
                <div className="cancel-point">
                <div className="">
                  <Card>
                    <CardBody className="cancel-cls">
                      <p>
                      <img src={cancel} alt="cancel"/>
                      </p>
                      <h2>Escrow not found.</h2>
                    </CardBody>
                  </Card>
                </div>
                </div>
              </Row>
            </Container>
          </>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  user: User.user,
});

const mapDispatchToProps = {
  escrowStatusUpdateRequest
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EscrowDetails));

