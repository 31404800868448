export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_FAILURE = 'DELETE_ACCOUNT_FAILURE';

export const CHECK_AUTH_REQUEST = 'CHECK_AUTH_REQUEST';
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS';
export const CHECK_AUTH_FAILURE = 'CHECK_AUTH_FAILURE';

export const CHANGE_AVATAR_REQUEST = 'CHANGE_AVATAR_REQUEST';
export const CHANGE_AVATAR_SUCCESS = 'CHANGE_AVATAR_SUCCESS';
export const CHANGE_AVATAR_FAILURE = 'CHANGE_AVATAR_FAILURE';

export const SET_LOADING = 'SET_LOADING';
export const GET_WORD_POOL = 'GET_WORD_POOL';
export const GET_WORD_POOL_SUCCESS = 'GET_WORD_POOL_SUCCESS';

export const UPDATE_NICKNAME = "UPDATE_NICKNAME_REQUEST";
export const UPDATE_NICKNAME_SUCCESS = "UPDATE_NICKNAME_SUCCESS";
export const UPDATE_NICKNAME_FAILURE = "UPDATE_NICKNAME_FAILURE";

export const FETCH_IDENTITY_REQUEST = "FETCH_IDENTITY_REQUEST";
export const FETCH_IDENTITY_SUCCESS = "FETCH_IDENTITY_SUCCESS";
export const FETCH_IDENTITY_FAILURE = "FETCH_IDENTITY_FAILURE";
export const WALLET_SUCCESS = "WALLET_SUCCESS";
export const WALLET_PRIMARY = "WALLET_PRIMARY";
export const UPDATE_LABEL = "UPDATE_LABEL";
export const ESCROW_SUCCESS = "ESCROW_SUCCESS";
export const UPDATE_ESCROW_STATUS_SUCCESS = "UPDATE_ESCROW_STATUS_SUCCESS";


