import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from "react-redux";
import withRouter from "../../components/Common/withRouter";
import PropTypes from "prop-types";
import channelAvatar from "../../assets/images/channel-avatar.svg";

import { 
  getChannelMessages,getFollowChannel
} from "../../store/actions";


class PublicChannel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel:null,
      messages:this.props.messages || [],
    };
  }
  componentDidMount() {
    const { router } = this.props;
    const { channelName } = router.params || {};
    if (channelName) {
      const { onGetChannelMessages } = this.props;
      onGetChannelMessages(channelName,
        (selectedchannel) => {
          this.setState({
            channel:selectedchannel
          });
        });
    } else {
      console.log(router,'channelName is not defined');
    }

  }
  followChannel = () => {
    const { router } = this.props;
    const { channelName } = router.params || {};
    if (channelName) {
      const { onGetFollowChannel } = this.props;
      onGetFollowChannel(channelName, (selectedchannel, login=null) => {
        if(login){
            this.props.router.navigate("/login")
        }
        this.setState({
          channel: selectedchannel,
        });
      });
    }
  }
  getMessagesGroupedByDate = (messages, searchQuery = '') => {
    let filteredMessages = messages;
    if (searchQuery.trim()) {
      filteredMessages = messages.filter(
        (message) =>
          message.content.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (message.fileName &&
            message.fileName.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    }
    return filteredMessages.reduce((acc, message) => {
      const date = moment(message.createdAt).format('ddd, DD MMMM YYYY');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  };

  render() {
    const { router } = this.props;
    const { channelName } = router.params || {};
    let messages = this.props.messages || [];
    const {channel} = this.state;
    const messagesGroupedByDate = this.getMessagesGroupedByDate(messages);
    return (
      <div className="main-channel-pub-cls" >
      {channel ? (
        <>
        <header id="page-topbar-channel" className='w-100 main-channel-topbar'>
          <div className='container'>
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    <div className="navbar-brand-box-top">
                      <img src={channel.avatar ? channel.avatar : channelAvatar}  className="h-100" alt="channel logo" />
                    </div>
                    <div className='name'>
                      <strong> {channel.name} </strong>
                      <div className="subscribers mb-0">
                        Members: <span>{channel.members}</span>
                      </div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                  <Link
                    onClick={() => this.followChannel(channelName)}
                    className="btn cryto-btn"
                  >
                    <span className='mobile-show'>
                      <i className='fas fa-paper-plane' style={{ paddingRight: '6px' }}></i> Follow
                    </span>
                    <span className='desktop-show'>
                      Follow in Resonance
                    </span>
                  </Link>
                </div>
            </div>
          </div>
        </header>
        <div className='w-100 pt-1 container'>
          <PerfectScrollbar className="chat-conversation-height-cls">
            <div className="chat-conversation p-3">
              <ul className="list-unstyled mb-0">
                <PerfectScrollbar className="main-point-cls">
                  {Object.keys(messagesGroupedByDate).length > 0 ? (
                    Object.keys(messagesGroupedByDate).map((date, idx) => (
                      <React.Fragment key={idx}>
                        <li className="text-center">{date}</li>
                        {messagesGroupedByDate[date].map((message, key) => (
                          <li
                            key={key}
                            className=""
                            style={{ paddingRight: '20px' }}
                          >
                            <div className="conversation-list">
                              <div className="ctext-wrap">
                                <p className="chat-time mb-0">
                                  {moment(message.createdAt).format(
                                    'hh:mm A'
                                  )}
                                </p>
                                <div className="ctext-wrap-content">
                                  <p className="mb-0">{message.content}</p>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </React.Fragment>
                    ))
                  ) : (
                    <li className="text-center">No messages to display.</li>
                  )}
                </PerfectScrollbar>
              </ul>
            </div>
          </PerfectScrollbar>
        </div>
        {/* <div className="channel-card">
          <div className="channel-logo">
            <img src={channel.avatar ? channel.avatar : channelAvatar}  className="w-100 h-100" alt="channel logo" />
          </div>
          <div className="channel-name">{channel.name}</div>
          <div className="subscribers">
            Members: <span>{channel.members}</span>
          </div>
          <Link
            onClick={() => this.followChannel(channelName)}
            className="btn cryto-btn mb-3"
          >
            Follow in Resonance
          </Link>
          <Link to={`/channel-preview/${channelName}`} className="btn-preview">
            Preview channel
          </Link>
        </div> */}
        </>
      ):(
        <div className="channel-card" style={{ margin: 'auto' }}>
          <div className="channel-logo">
            <img src={channelAvatar}  className="w-100 h-100" alt="channel logo" />
          </div>
          <div className="channel-name">No channel found</div>
          <div className="subscribers">
            Members: <span>0</span>
          </div>
          <Link to="" className="btn-preview">
            Invalid Link
          </Link>
        </div>
      )}
    </div>
    );
  }
}

PublicChannel.propTypes = {
  messages: PropTypes.array,
};

const mapStateToProps = ({ chat}) => ({
  messages: chat.messages,
});

const mapDispatchToProps = (dispatch) => ({
  onGetChannelMessages: (channelName,callback) =>
    dispatch(getChannelMessages(channelName,callback)),
  onGetFollowChannel: (channelName, callback) =>
    dispatch(getFollowChannel(channelName, callback)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(PublicChannel));
