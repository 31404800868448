import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import apis from './apis.json';
import logo from "../../assets/images/resonance.svg";

const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}/user`
const apiSpec = {
  ...apis,
  servers: [
    {
      url: apiBaseUrl,
    },
  ],
};
const SwaggerUIComponent = () => {
  return (
    <React.Fragment>
      <div className="page-content page-profile m-3 p-5">
        <div className="d-lg-flex">
          <div className="w-100 user-chat-cls mt-sm-0">
            <div className="px-lg-2">
              <div className="d-flex justify-content-center align-items-center">
                <div className="profile-w">
                  <div className="chat-conversation p-3 chat-conversation-height">
                    <div className="text-center">
                      <img src={logo} alt="resonance" style={{ height: '100px', width: '100px' }} />
                    </div>
                    <SwaggerUI spec={apiSpec} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SwaggerUIComponent;
