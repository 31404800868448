import React, { Component } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { AvForm, AvInput } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import timer from "../../../assets/images/timer.png";
import arrow from "../../../assets/images/arrow.png";
import src from "../../../assets/images/create new.png";
import { connect } from "react-redux";
import { createEscrowRequest } from "../../../store/actions";

class NewEscrow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      currency:"USD",
      user: props.user || [],
      form: {
        sellerIdentity: "",
        amount: "",
        date: "",
        timeDuration: "15",
        walletId: "",
        attachment: null,
        description: "",
      },
    };
  }

  toggleForm = () => {
    this.setState((prevState) => ({
      showForm: !prevState.showForm,
    }));
  };

  handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "walletId") {
      const selectedWallet = this.state.user?.identity?.wallets?.find(
        (wallet) => wallet.walletId === value
      );
      if (selectedWallet) {
        this.setState({
          currency:selectedWallet.coin.toUpperCase() 
        });
      }
    }

    if (name === "attachment") {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          attachment: files[0],
        },
      }));
    } else {
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          [name]: value,
        },
      }));
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { createEscrowRequest } = this.props;
    const { form } = this.state;
    createEscrowRequest(form, (newEscrow) => {
      if (newEscrow) {
        this.props.onMenuItemClick(`/finance/escrow-details/${newEscrow.id}`);
      }
    });
  };

  render() {
    const {user,currency} = this.state;
    return (
      <React.Fragment>
        <div className="crypto-content">
          {!this.state.showForm ? (
            <h1 className="crypto-heading m-0">
              <Link to="#" className="text-muted">
                <img src={arrow} alt="Arrow" />
              </Link>
              <span className="mx-2">ESCROW</span>
              <img src={timer} alt="Timer" />
            </h1>
          ) : (
            <h1 className="crypto-heading m-0">
              <img src={arrow} alt="Arrow" onClick={this.toggleForm} />
              <span className="mx-2">ESCROW DETAILS</span>
              <img src={timer} alt="Timer" />
            </h1>
          )}
          <Container fluid={true} className="step-back">
            {!this.state.showForm ? (
              <div className="create-new-cls">
                <div className="text-center">
                  <div className="create-new" onClick={this.toggleForm}>
                    <img src={src} alt="Create New" />
                    <div className="mt-3">
                      <h2>Create New</h2>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Row>
                <Col lg={12}>
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={this.handleSubmit}
                  >
                    <div className="detail-form">
                      <label className="form-label-cls">Saller Identity</label>
                      <AvInput
                        name="sellerIdentity"
                        value={this.state.form.sellerIdentity}
                        validate={{ required: true }}
                        onChange={this.handleChange}
                        type="text"
                        className="form-control form-text-cls"
                        id="name"
                        placeholder="Id address"
                      />
                    </div>
                    <div className="detail-form">
                      <label className="form-label-cls">Amount</label>
                      <div className="input-group">
                        <AvInput
                          type="number"
                          className="form-control form-text-cls"
                          name="amount"
                          placeholder="0.00"
                          value={this.state.form.amount}
                          onChange={this.handleChange}
                          validate={{ required: true }}
                        />
                        <div className="input-group-append">{currency}</div>
                      </div>
                    </div>

                    <Row>
                      <Col lg={6} sm={12}>
                        <div className="detail-form">
                          <label className="form-label-cls">Date</label>
                          <AvInput
                            type="date"
                            className="form-control form-text-cls"
                            name="date"
                            value={this.state.form.date}
                            onChange={this.handleChange}
                            validate={{ required: true }}
                          />
                        </div>
                      </Col>
                      <Col lg={6} sm={12}>
                        <div className="detail-form">
                          <label className="form-label-cls">
                            Time duration
                          </label>
                          <select
                            className="form-control form-text-cls"
                            name="timeDuration"
                            value={this.state.form.timeDuration}
                            onChange={this.handleChange}
                            required
                          >
                            <option value="" disabled>
                              Select Time Duration
                            </option>
                            <option value="5">5 minute</option>
                            <option value="15">15 minute</option>
                            <option value="20">20 minute</option>
                            <option value="30">30 minute</option>
                            <option value="60">1 hour</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Col lg={12}>
                      <div className="detail-form">
                        <label className="form-label-cls">Wallet</label>
                        <select
                          className="form-control form-text-cls"
                          name="walletId"
                          value={this.state.form.walletId}
                          onChange={this.handleChange}
                          required
                        >
                            {user && user.identity && user.identity.wallets
                            ? user.identity.wallets.map((wallet) => (
                                <option key={wallet.id} value={wallet.walletId}>
                                  {wallet.label} {Number(wallet.balance).toFixed(2)} {wallet.coin.toUpperCase()}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                    </Col>
                    <div className="detail-form">
                      <label className="form-label-cls">Attachment</label>
                      <div className="custom-file">
                        <div className="file-name mr-auto form-control form-text-cls">
                          {this.state.form.attachment
                            ? this.state.form.attachment.name
                            : "PDF/JPG/PNG"}
                        </div>
                        <label
                          className="custom-file-label"
                          htmlFor="attachment"
                        >
                          Upload File
                          <input
                            type="file"
                            className="custom-file-input"
                            id="attachment"
                            name="attachment"
                            onChange={this.handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>

                    <div className="detail-form">
                      <label className="form-label-cls">Description</label>
                      <AvInput
                        className="form-control form-text-cls"
                        name="description"
                        value={this.state.form.description}
                        onChange={this.handleChange}
                        validate={{ required: true }}
                        type="textarea"
                        placeholder="Subject Here....."
                      />
                    </div>
                    <Button
                      type="submit"
                      className="d-flex mx-auto btn cryto-btn"
                    >
                      SENT REQUEST
                    </Button>
                  </AvForm>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  user: User.user,
});
const mapDispatchToProps = {
  createEscrowRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewEscrow);
