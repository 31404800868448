import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  Input,
  Label,
  ModalHeader
} from "reactstrap";
import arrow from "../../assets/images/arrow.png";
import BTC from "../../assets/images/BTC (bitcoin).png";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import { connect } from "react-redux";
import { changePreloader } from "../../store/layout/actions";
import { sendMoneyRequest,markAsPrimaryRequest,updateWalletLabelRequest } from "../../store/actions";
import { toast } from "react-toastify";
import src from "../../assets/images/create new.png";
import ModelForm from "../../components/Form/Form";
import { Tooltip } from 'react-tooltip';

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wallet:
        props.user?.identity?.wallets?.find(
          (wallet) =>
            wallet.walletId === props.router.location.pathname.split("/").pop()
        ) || [],
      selectedTimeframe: "1D",
      series: [
        {
          name: "Price",
          data: [],
        },
      ],
      options: {
        chart: {
          zoom: { enabled: !1 },
          toolbar: { show: !1 },
        },
        colors: ["#1877f2"],
        dataLabels: { enabled: !1 },
        stroke: { width: [3], curve: "straight" },
        markers: { size: 0, hover: { sizeOffset: 6 } },
        xaxis: {
          categories: [],
          labels: {
            show: false, // Hide the x-axis labels
          },
          axisBorder: {
            show: false, // Hide the x-axis border
          },
          axisTicks: {
            show: false, // Hide the x-axis ticks
          },
        },
        yaxis: {
          labels: {
            show: false, // Hide the y-axis labels
          },
          axisBorder: {
            show: false, // Hide the y-axis border
          },
          axisTicks: {
            show: false, // Hide the y-axis ticks
          },
        },
        tooltip: {
          y: {
            title: {
              formatter: function (e) {
                return e;
              },
            },
          },
        },
        grid: {
          borderColor: "#f1f1f1",
          show: false,
        },
      },
      amount: 0,
      receiverAddress: "",
      sendModal: false,
      receiveModal: false,
      labelModal: false,
      errors: [],
      label:'', 
    };
    this.toggleSendModal = this.toggleSendModal.bind(this);
    this.toggleLabelModal = this.toggleLabelModal.bind(this);
    this.toggleReceiveModal = this.toggleReceiveModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendSubmit = this.handleSendSubmit.bind(this);
    this.handleMarkAsPrimary = this.handleMarkAsPrimary.bind(this);
    
  }
  componentDidMount() {
    const { router, user } = this.props;
    const walletId = router.location.pathname.split("/").pop();
    const walletData = user?.identity?.wallets?.find(
      (wallet) => wallet.walletId === walletId
    );
    if (walletData) {
      this.setState({
        wallet: walletData,
        label:walletData.label
      });
      this.fetchChartData(walletData.blockchain, "1");
    }
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const currentWalletId = this.props.router.location.pathname.split("/").pop();
    const previousWalletId = prevProps.router.location.pathname.split("/").pop();
    if (currentWalletId !== previousWalletId) {
      const walletData = user?.identity?.wallets?.find((wallet) => wallet.walletId === currentWalletId);
      this.setState({
        wallet: walletData,
        label:walletData.label,
      });
      this.fetchChartData(walletData.blockchain, "1");
    }
  }
  fetchChartData = async (blockchain, days) => {
    this.props.changePreloader(true);
    const url = `https://api.coingecko.com/api/v3/coins/${blockchain}/market_chart?vs_currency=usd&days=${days}`;
    try {
      const response = await fetch(url);
      if (!response.ok)
        throw new Error(`Unexpected response status: ${response.status}`);

      const data = await response.json();
      const prices = data.prices.map((price) => price[1]);
      const timestamps = data.prices.map((price) =>
        new Date(price[0]).toLocaleDateString()
      );

      this.setState({
        series: [{ name: "Price", data: prices }],
        options: { ...this.state.options, xaxis: { categories: timestamps } },
      });
    } catch (error) {
      toast.error("Error fetching chart data:");
    } finally {
      this.props.changePreloader(false);
    }
  };

  handleTimeframeChange = (timeframe) => {
    let days;
    switch (timeframe) {
      case "1D":
        days = 1;
        break;
      case "1W":
        days = 7;
        break;
      case "1M":
        days = 30;
        break;
      case "1Y":
        days = 365;
        break;
      case "ALL":
        days = "max";
        break;
      default:
        days = 7;
    }
    this.setState({ selectedTimeframe: timeframe });
    this.fetchChartData(this.state.wallet.blockchain, days);
  };
  toggleSendModal = () => {
    this.setState((prevState) => ({
      sendModal: !prevState.sendModal,
    }));
  };
  toggleLabelModal = () => {
    this.setState((prevState) => ({
      labelModal: !prevState.labelModal,
    }));
  };
  toggleReceiveModal = () => {
    this.setState((prevState) => ({
      receiveModal: !prevState.receiveModal,
    }));
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => this.validateField(name, value));
  };
  validateField = (name, value) => {
    let errors = this.state.errors;
    switch (name) {
      case "amount":
        if (!value || value < 1) {
          errors.amount = "Amount is required.";
        }
      case "receiverAddress":
        if (!value) {
          errors.receiverAddress = "Receiver address is required.";
        }
        break;
      default:
        break;
    }
    this.setState({ errors });
  };
  handleCopy = (field, value) => {
    navigator.clipboard.writeText(value).then(
      () => {
        toast.success(`${field} copied successfully.`);
      },
      (err) => {
        toast.error(`Could not copy ${field}: `, err);
      }
    );
  };
  handleSendSubmit = (e) => {
    e.preventDefault();
    const { receiverAddress, amount, wallet } = this.state;
    const {walletId} = wallet;
    const { sendMoneyRequest } = this.props;
    sendMoneyRequest(amount, receiverAddress, walletId,(success) => {
      if(success){
        this.setState((prevState) => ({
          amount:0,
          receiverAddress:'',
        }));
        this.toggleSendModal();
      }
    });
  };
  handleLabelSubmit = (e) => {
    e.preventDefault();
    const { wallet,label } = this.state;
    const { updateWalletLabelRequest } = this.props;
    updateWalletLabelRequest(wallet.walletId,label,() => {
      this.setState((prevState) => ({
        wallet: {
          ...prevState.wallet,
          label: label, 
        },
      }));
      this.toggleLabelModal();
    });
  };
  handleMarkAsPrimary = (e) => {
    e.preventDefault();
    const { walletId } = this.state.wallet;
    const { markAsPrimaryRequest } = this.props;
    markAsPrimaryRequest(walletId,() => {
        this.setState((prevState) => ({
          wallet: {
            ...prevState.wallet,
            isPrimary: !prevState.wallet.isPrimary,
          },
        }));
    });
  };
  render() {
    const { wallet, amount, receiverAddress, sendModal, errors, receiveModal, labelModal, label } = this.state;
    const sendFields = [
      {
        name: "receiverAddress",
        label: "Receiver Wallet Address",
        value: receiverAddress,
        type: "text",
        placeholder: "Enter receiver wallet address.",
        required: true,
      },
      {
        name: "amount",
        label: "Amount",
        value: amount,
        type: "number",
        placeholder: "Enter amount.",
        required: true,
      },
    ];
    const labelFields = [
      {
        name: "label",
        label: "Wallet Label",
        value: label,
        type: "text",
        placeholder: "Enter wallet label.",
        required: true,
      },
    ];
    return (
      <React.Fragment>
        <div className="Bitcoin-content">
          {Object.keys(wallet).length > 0 ? (
            <>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <h1 className="d-flex bitcoin-clr">
                    <Link to="#" className="text-muted">
                      <img src={arrow} alt="Arrow" />
                    </Link>
                    <span className="mx-2">Your {wallet.blockchain}</span>
                    <ul className="list-inline user-chat-nav m-0">
                      <li className="list-inline-item">
                        <Dropdown
                          isOpen={this.state.other2}
                          toggle={() =>
                            this.setState({ other2: !this.state.other2 })
                          }
                        >
                          <DropdownToggle className="btn nav-btn" tag="i">
                            <i className="mdi mdi-dots-horizontal"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem onClick={this.toggleLabelModal}>
                              <i className="ri-arrow-right-line"></i> Manage
                              name of wallet
                            </DropdownItem>
                            <DropdownItem onClick={this.handleMarkAsPrimary}>
                              <i className="ri-arrow-right-line"></i>{wallet.isPrimary ? "Remove" : "Make"} as primary</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </li>
                    </ul>
                  </h1>
                </Col>
              </Row>
              <Container fluid={true} className="step-back mb-5">
                <Row>
                  <Col lg={12} className="wallet-main">
                    <img src={BTC} alt="BTC" />
                    <p className="mt-2 mb-0">$1523.68</p>
                    <h1 className="my-2 btc-heading">
                      {Number(wallet.balance).toFixed(2)}{" "}
                      {wallet.coin.toUpperCase()}
                    </h1>
                    <p className="text-green">+404.40 (0.78%)</p>
                  </Col>
                </Row>

                <Row className="justify-content-center">
                  <Col lg="12">
                    <ReactApexChart
                      options={this.state.options}
                      series={this.state.series}
                      type="area"
                      height="270"
                    />
                    <Row className="justify-content-center">
                      <Col lg="6" className="date-btn">
                        <span
                          className={`custom-btn ${
                            this.state.selectedTimeframe === "1D"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => this.handleTimeframeChange("1D")}
                        >
                          1D
                        </span>
                        <span
                          className={`custom-btn ${
                            this.state.selectedTimeframe === "1W"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => this.handleTimeframeChange("1W")}
                        >
                          1W
                        </span>
                        <span
                          className={`custom-btn ${
                            this.state.selectedTimeframe === "1M"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => this.handleTimeframeChange("1M")}
                        >
                          1M
                        </span>
                        <span
                          className={`custom-btn ${
                            this.state.selectedTimeframe === "1Y"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => this.handleTimeframeChange("1Y")}
                        >
                          1Y
                        </span>
                        <span
                          className={`custom-btn ${
                            this.state.selectedTimeframe === "ALL"
                              ? "active"
                              : ""
                          }`}
                          onClick={() => this.handleTimeframeChange("ALL")}
                        >
                          ALL
                        </span>
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col lg="12" className="text-center">
                        <Button className="cryto-btn m-2" onClick={this.toggleSendModal}>
                          <p className="m-0">
                            Send
                          </p>
                        </Button>
                        <Button color="secondary" className="bitcoin-BTN m-2"  onClick={this.toggleReceiveModal}>
                          <p className="m-0">Receive</p>
                        </Button>
                        <Button color="secondary" className="m-2 bitcoin-BTN">
                          <p className="m-0">Swap</p>
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container>
                <div className="cancel-point">
                  <div className="width-cls">
                    <div className="create-new-cls">
                      <div className="text-center">
                        <Link to="/finance/create-wallet">
                          <div className="create-new">
                            <img src={src} alt="Create New" />
                            <div className="mt-3">
                              <h2>Create New</h2>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </>
          )}
          <ModelForm
            isOpen={sendModal}
            toggle={this.toggleSendModal}
            handleSubmit={this.handleSendSubmit}
            fields={sendFields}
            errors={errors}
            title="Send"
            handleInputChange={this.handleInputChange}
            saveButton="Send"
          />
           <ModelForm
            isOpen={labelModal}
            toggle={this.toggleLabelModal}
            handleSubmit={this.handleLabelSubmit}
            fields={labelFields}
            errors={errors}
            title="Update Label"
            handleInputChange={this.handleInputChange}
            saveButton="Update"
          />
          <Modal isOpen={receiveModal} className="modal-dialog-centered">
            <div className="bg-modal">
              <ModalHeader className="modal-header-custom pb-1">
                <h2>Wallet Address</h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.toggleReceiveModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </ModalHeader>
              <ModalBody className="custom-modal-body">
                {wallet ? (
                  <>
                    <div className="id-mar">
                      <Label className="form-label id-color">
                        Wallet address
                      </Label>
                      <div className="input-with-icon id-input">
                        <Input
                          type="textarea"
                          className="form-control"
                          value={wallet.address}
                          readOnly
                        />
                        <i
                          className="ri-file-copy-line input-icon pointer"
                          data-tooltip-id="id-name-tooltip"
                          data-tooltip-content="Copy wallet address."
                          onClick={() =>
                            this.handleCopy("wallet address", wallet.address)
                          }
                        ></i>
                        <Tooltip id="id-name-tooltip" />
                      </div>
                    </div>
                  </>
                ) : null}
              </ModalBody>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({ User }) => ({
  user: User.user,
});

const mapDispatchToProps = (dispatch) => ({
  changePreloader:(status) => dispatch(changePreloader(status)),
  sendMoneyRequest:(amount, receiverAddress, walletId,callback) => dispatch(sendMoneyRequest(amount, receiverAddress, walletId,callback)),
  markAsPrimaryRequest:(walletId,callback) => dispatch(markAsPrimaryRequest(walletId,callback)),
  updateWalletLabelRequest:(walletId,label,callback) => dispatch(updateWalletLabelRequest(walletId,label,callback)),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Wallet));
