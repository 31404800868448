import { call, put, takeLatest, fork, select, take } from "redux-saga/effects";
import axios from "../../util/axiosConfig";
import { createEscrowSuccess, createEscrowFailure } from './actions';
import { CREATE_ESCROW_REQUEST, ESCROW_RECEIVED, ESCROW_STATUS_UPDATE, ESCROW_STATUS_UPDATE_REQUEST,UPDATE_ESCROW_FAILURE} from './actionTypes';
import { CHANGE_PRELOADER } from "../layout/actionTypes";
import { toast } from "react-toastify";
import { ESCROW_SUCCESS,UPDATE_ESCROW_STATUS_SUCCESS } from "../auth/user/actionTypes";
import socket from '../../util/socket';
import { eventChannel } from 'redux-saga';

function* createEscrow(action) {
  try {
    yield put({ type: CHANGE_PRELOADER, payload: true });
    const formData = new FormData();
    for (const key in action.payload.escrowData) {
      formData.append(key, action.payload.escrowData[key]);
    }
    const response = yield call(axios.post, '/user/escrow/create', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    yield put(createEscrowSuccess(response.data));
    yield put({ type: ESCROW_SUCCESS, payload: response.data.escrow });
    toast.success(response.data.message);
    socket.emit('escrow', { escrow: response.data.escrow });

    if (action.payload.callback) {
      setTimeout(() => {
        action.payload.callback(response.data.escrow);
      }, 300);
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || "Something went wrong, please try again.";
    yield put(createEscrowFailure(errorMessage));
    toast.error(errorMessage);
    if (action.payload.callback) {
      action.payload.callback(false);
    }
  } finally {
    yield put({ type: CHANGE_PRELOADER, payload: false });
  }
}
function* emitEscrowStatusUpdate(action) {
  yield put({ type: CHANGE_PRELOADER, payload: true });
  const { escrowId, status, file, filename, sellerWallet } = action.payload;
  try {
    socket.emit('escrowStatusUpdate', { escrowId, status, file, filename, sellerWallet });
    yield new Promise((resolve, reject) => {
      socket.once('escrowStatusUpdated', (updatedEscrow) => {
        resolve();
        put({ type: UPDATE_ESCROW_STATUS_SUCCESS, payload: updatedEscrow });
      });
    });
    if (action.payload.callback) {
      setTimeout(() => {
        action.payload.callback(escrowId);
      }, 300);
    }
    yield put({ type: CHANGE_PRELOADER, payload: false });
  } catch (error) {
    toast.error('Error while updating escrow status.');
    yield put({ type: CHANGE_PRELOADER, payload: false });
  }
}
function createSocketEscrow() {
  return eventChannel((emit) => {
    const escrowHandler = (escrowData) => {
      emit({ type: ESCROW_RECEIVED, payload: escrowData });
    };
    const escrowStatusUpdatedHandler = (escrow) => {
      emit({ type: ESCROW_STATUS_UPDATE, payload: escrow });
    };
    const errorHandler = (escrow,error) => {
      emit({ type: UPDATE_ESCROW_FAILURE, payload: {escrow,error} });
    };
    socket.on('escrowCreated', escrowHandler);
    socket.on('escrowStatusUpdated', escrowStatusUpdatedHandler);
    socket.on('error', errorHandler);
    return () => {
      socket.off('escrowCreated', escrowHandler);
      socket.off('escrowStatusUpdated', escrowStatusUpdatedHandler);
      socket.off('error', errorHandler);
    };
  });
}

function* watchEscrowEvent() {
  const escrowChannel = yield call(createSocketEscrow);
  while (true) {
    const action = yield take(escrowChannel);
    const user = yield select((state) => state.User.user); 
    if (action.type === ESCROW_RECEIVED) {      
      const { escrow } = action.payload;
      if (user && escrow.sellerIdentity === user.identity.address) {
        yield put({ type: ESCROW_SUCCESS, payload: escrow });
        toast.success('New escrow request received.');
      }
    }
    if (action.type === ESCROW_STATUS_UPDATE ) {
        const { escrow } = action.payload;
        if (escrow.sellerIdentity === user.identity.address || escrow.buyerIdentity === user.identity.address){
          const updatedEscrow = {escrow}; 
          toast.success(`Escrow ${escrow.status}.`);
          yield put({ type: UPDATE_ESCROW_STATUS_SUCCESS, payload: updatedEscrow });        
        }
    }
    if (action.type === UPDATE_ESCROW_FAILURE ) {
      const { escrow, error } = action.payload;
      if (escrow.sellerIdentity === user.identity.address || escrow.buyerIdentity === user.identity.address){
        toast.error(error);
      }
    }
  }

}

function* escrowSaga() {
  yield takeLatest(CREATE_ESCROW_REQUEST, createEscrow);
  yield takeLatest(ESCROW_STATUS_UPDATE_REQUEST, emitEscrowStatusUpdate);
  yield fork(watchEscrowEvent); 
}

export default escrowSaga;
