export const CREATE_WALLET_REQUEST ="CREATE_WALLET_REQUEST";
export const CREATE_WALLET_SUCCESS = "CREATE_WALLET_SUCCESS"; 
export const CREATE_WALLET_FAILURE = "CREATE_WALLET_FAILURE";

export const FETCH_CHART_DATA_REQUEST = "FETCH_CHART_DATA_REQUEST";
export const FETCH_CHART_DATA_SUCCESS = "FETCH_CHART_DATA_SUCCESS";
export const FETCH_CHART_DATA_FAILURE = "FETCH_CHART_DATA_FAILURE";

export const SEND_MONEY_REQUEST = 'SEND_MONEY_REQUEST';
export const SEND_MONEY_SUCCESS = 'SEND_MONEY_SUCCESS';
export const SEND_MONEY_FAILURE = 'SEND_MONEY_FAILURE';

export const MARK_AS_PRIMARY_REQUEST = "MARK_AS_PRIMARY_REQUEST";
export const MARK_AS_PRIMARY_SUCCESS = "MARK_AS_PRIMARY_SUCCESS";
export const MARK_AS_PRIMARY_FAILURE = "MARK_AS_PRIMARY_FAILURE";

export const UPDATE_WALLET_LABEL_REQUEST = "UPDATE_WALLET_LABEL_REQUEST";
export const UPDATE_WALLET_LABEL_SUCCESS = "UPDATE_WALLET_LABEL_SUCCESS";
export const UPDATE_WALLET_LABEL_FAILURE = "UPDATE_WALLET_LABEL_FAILURE";
