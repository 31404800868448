import React from "react";
// import { Link } from "react-router-dom";
import defaultSrc from "../../assets/images/auth/icon.svg";

const AuthIcon = ({ src = defaultSrc }) => {
  return (
    <div className='col-lg-12'>
        <img className="logo-icon pb-4" src={src} alt="logo" />
    </div>
  );
};

export default AuthIcon;
