// actions.js
import { CREATE_ESCROW_SUCCESS, CREATE_ESCROW_FAILURE, CREATE_ESCROW_REQUEST, ESCROW_RECEIVED,UPDATE_ESCROW_FAILURE,ESCROW_STATUS_UPDATE_REQUEST } from './actionTypes';

export const createEscrowSuccess = (data) => ({
  type: CREATE_ESCROW_SUCCESS,
  payload: data,
});

export const createEscrowFailure = (error) => ({
  type: CREATE_ESCROW_FAILURE,
  payload: error,
});

export const createEscrowRequest = (escrowData, callback) => ({
  type: CREATE_ESCROW_REQUEST,
  payload: { escrowData, callback },
});

export const escrowReceived = (escrowData) => ({
  type: ESCROW_RECEIVED,
  payload: escrowData, 
});
export const escrowStatusUpdateRequest = (escrowId, status,file, filename, sellerWallet, callback) => ({
  type: ESCROW_STATUS_UPDATE_REQUEST,
  payload: { escrowId, status,file, filename, sellerWallet, callback },
});

export const UpdateEscrowFailure = (error) => ({
  type: UPDATE_ESCROW_FAILURE,
  payload: {error},
});