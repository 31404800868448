import React, { Component } from "react";
import { Row, Col, Container, Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import timer from "../../../assets/images/timer.png";
import arrow from "../../../assets/images/arrow.png";
import cancel from "../../../assets/images/cancel.png";
import btc from "../../../assets/images/btc.png";
import withRouter from "../../../components/Common/withRouter";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { escrowStatusUpdateRequest } from "../../../store/actions";

class EscrowDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user || [],
      showTerms: true,
      escrow: [],
      timeLeft: null,
      showAttachModal: false,
      selectedFile: null,
      filename:'',
      sellerWallet:'',
      status: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const currentEscrowId = this.props.router.location.pathname.split("/").pop();

    if (currentEscrowId) {
      const escrowData = user?.identity?.escrows?.find((escrow) => escrow.id == currentEscrowId);
      if (escrowData) {
        const status = escrowData.status === "rejected" ? true : false;
        this.setState(
          {
            escrow: escrowData,
            showTerms: status,
          },
          () => {
            if (escrowData.startTime && escrowData.endTime) {
              this.startTimer(escrowData.startTime, escrowData.endTime);
            }
          }
        );
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const currentEscrowId = this.props.router.location.pathname.split('/').pop();
    const previousEscrowId = prevProps.router.location.pathname.split('/').pop();
    if (currentEscrowId !== previousEscrowId) {
      const escrowData = user?.identity?.escrows?.find((escrow) => escrow.id == currentEscrowId);
      if (escrowData) {
        const status = escrowData.status === 'rejected' ? true : false;
        this.setState(
          {
            escrow: escrowData,
            showTerms: status,
          },
          () => {
            if (escrowData.startTime && escrowData.endTime) {
              this.startTimer(escrowData.startTime, escrowData.endTime);
            }
          }
        );
      }
    }
  }
  startTimer = (startTime, endTime) => {
    const end = new Date(endTime).getTime();
    const start = new Date(startTime).getTime();
    const now = Date.now();
    let timeLeft = end - now;
    if (timeLeft < 0) timeLeft = 0;
    this.timerInterval = setInterval(() => {
      timeLeft = end - Date.now();
      if (timeLeft <= 0) {
        clearInterval(this.timerInterval);
        timeLeft = 0;
      }
      this.setState({
        timeLeft,
      });
    }, 1000);
  };
  formatTime = (milliseconds) => {
    const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };
  downloadFile = async (fileType) => {
    const { escrow } = this.state;
    const fileUrl = fileType === "attachment" ? escrow.attachment : escrow.proofOfWork;

    if (!fileUrl) {
      toast.error("File not available for download.");
      return;
    }

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      const filename = fileUrl.split("/").pop();

      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success("Attachment downloaded successfully.");
    } catch (error) {
      toast.error("Error downloading file, please try again.");
    }
  };
  handleUnderstandClick = () => {
    this.setState({ showTerms: false });
  };
  toggleAttachModal = () => {
    this.setState({ showAttachModal: !this.state.showAttachModal });
  };
  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ 
        selectedFile: file,
        filename: file.name
      });
    }
  };
  handleAskForReleaseSubmit = () => {
    if (!this.state.selectedFile) {
      toast.error("Please select a file to upload.");
      return;
    }
    const { escrow } = this.state;
    const { escrowStatusUpdateRequest } = this.props;
    if (!escrow) return;
    escrowStatusUpdateRequest(escrow.id, "asked-release" ,this.state.selectedFile, this.state.filename, this.state.sellerWallet, (escrowId) => {
      if (escrowId) {
        toast.success("Release request submitted successfully.");
        this.setState({ showAttachModal: false, selectedFile: null });    
        this.props.onMenuItemClick(`/finance/freezed-escrow/${escrowId}`);
      }}
    );
  };
  handleReleaseSubmit = () => {
    const { escrow } = this.state;
    const { escrowStatusUpdateRequest } = this.props;
    if (!escrow) return;
    escrowStatusUpdateRequest(escrow.id, "released" ,null, null, null, (escrowId) => {
      if (escrowId) {
        toast.success("Escrow Released successfully.");
        this.props.onMenuItemClick(`/finance/freezed-escrow/${escrowId}`);
      }}
    );
  };
  handleInputChange(event) {
    const { name, value } = event.target;
    this.setState({ 
      [name]: value,
     
    });
  };
  render() {
    const { escrow, user, timeLeft , showAttachModal } = this.state;
    return (
      <React.Fragment>
        <div className="page-freeze">
          {Object.keys(escrow).length > 0 ? (
            <>
              {this.state.showTerms ? (
                <Container>
                  <Row className="">
                    <div className="cancel-point">
                      <div className="width-cls">
                        <Card>
                          <CardBody className="cancel-cls">
                            <p>
                              <img src={cancel} alt="cancel" />
                            </p>
                            <h2>CANCELLED</h2>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </Row>
                </Container>
              ) : (
                <>
                  {escrow.status === "pending" ? (
                    <Container>
                      <Row className="">
                        <div className="cancel-point">
                          <div className="">
                            <Card>
                              <CardBody className="cancel-cls">
                                <p>
                                  <img src={cancel} alt="cancel" />
                                </p>
                                <h2>Escrow is in pending.</h2>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      </Row>
                    </Container>
                  ) : (
                    <Container>
                      <div className="crypto-content">
                        <h1 className="crypto-heading m-0">
                          <Link to="/finance/new-escrow" className="text-muted">
                            <img src={arrow} alt="Arrow" />
                          </Link>
                          <span className="mx-2">ESCROW</span>
                          <img src={timer} alt="Timer" />
                        </h1>
                      </div>
                      <Row>
                        <Col lg={12}>
                          <div className="frez-cls">
                            <Card className="progrs-timer">
                              <CardBody>
                                <p>IN PROGRESS</p>
                                <h1>{timeLeft !== null ? this.formatTime(timeLeft) : "00:00:00"}</h1>
                              </CardBody>
                            </Card>
                          </div>
                          <Row>
                            <Col lg={12}>
                              <div className="escrow-details">
                                <div className="detail-item">
                                  <span className="label">Seller:</span>
                                  <span className="value">{escrow.sellerIdentity}</span>
                                </div>
                                <div className="detail-item">
                                  <span className="label">Date:</span>
                                  <span className="value">
                                    {new Date(escrow.date).toLocaleDateString()}
                                  </span>
                                </div>
                                <div className="detail-item">
                                  <span className="label">Time Duration:</span>
                                  <span className="value">{escrow.timeDuration} minutes</span>
                                </div>
                                <div className="detail-item">
                                  <span className="label">Type:</span>
                                  <span className="value">
                                    {escrow.blockchain}
                                    <img src={btc} alt="Timer" />
                                  </span>
                                </div>
                                <div className="detail-item pb-1">
                                  <span className="label">Service Fee:</span>
                                  <span className="value">3%</span>
                                </div>
                                <div className="detail-item pb-1">
                                  <span className="label">Attachments:</span>
                                  <Button className="download-btn" size="sm" onClick={() => this.downloadFile("attachment")}>
                                    Download Attachment <i className="fa fa-download"></i>
                                  </Button>
                                </div>
                                <div className="detail-item pb-0">
                                  <span className="label">Description:</span>
                                </div>
                                <div className="detail-item">
                                  <span className="discription-cls">{escrow.description}</span>
                                </div>
                                {escrow.proofOfWork && (
                                  <div className="detail-item pb-1">
                                    <span className="label">Proof Of Work:</span>
                                    <Button className="download-btn" size="sm" onClick={() => this.downloadFile("proofOfWork")}>
                                      Download Proof of Work
                                      <i className="fa fa-download"></i>
                                    </Button>
                                  </div>
                                )}
                                <div className="detail-item mt-3">
                                  <span className="label amount-cls">Amount:</span>
                                  <span className="value amount-cls">
                                    {Number(escrow.amount).toFixed(2)} {escrow.coin.toUpperCase()}
                                  </span>
                                </div>
                             
                                <div className="actions-cls text-center">
                                  <Button className="me-2 reject-btn" onClick={this.handleSubmit}>
                                    DISPUTE
                                  </Button>
                                  {escrow.sellerIdentity === user.identity.address  ? (
                                    <Button className="btn cryto-btn"  onClick={this.toggleAttachModal}>
                                      ASK FOR RELEASE
                                    </Button>
                                  ) : (
                                    <>
                                     {escrow.buyerIdentity === user.identity.address && escrow.status ==="asked-release" && (
                                      <Button className="btn cryto-btn" onClick={this.handleReleaseSubmit}>
                                        RELEASE
                                      </Button>
                                     )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Modal isOpen={showAttachModal} toggle={this.toggleAttachModal} className="modal-dialog-centered">
                        <div className="bg-modal">
                          <ModalHeader className="modal-header-custom" toggle={this.toggleAttachModal}>
                          <h2>Attach File for Release</h2>
                          </ModalHeader>   
                          <ModalBody className="bg-cls-txt">
                            <AvForm className="form-horizontal"  onValidSubmit={this.handleAskForReleaseSubmit}>
                              <ModalBody>
                              <Label htmlFor="attachment" className="form-label-cls">Choose File</Label>
                                <AvField
                                  type="file"
                                  name="attachment"
                                  // label="Choose File"
                                  id="attachment"
                                  validate={{
                                    required: { value: true, errorMessage: "Please select a file to upload." },
                                  }}
                                  onChange={this.handleFileChange}
                                />
                                 <Label htmlFor="sellerWallet" className="form-label-cls">Wallet Address ({escrow.coin})</Label>
                                <AvField
                                  type="text"
                                  name="sellerWallet"
                                  // label="Choose File"
                                  id="sellerWallet"
                                  validate={{
                                    required: { value: true, errorMessage: "Wallet address is required." },
                                  }}
                                  onChange={this.handleInputChange}
                                />
                              </ModalBody>
                              <ModalFooter>
                                <Button color="primary" type="submit">
                                  Submit
                                </Button>{" "}
                                <Button color="secondary" onClick={this.toggleAttachModal}>
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </AvForm>
                          </ModalBody>
                        </div>
                      </Modal>
                    </Container>
                  )}
                </>
              )}
            </>
          ) : (
            <Container>
              <Row className="">
                <div className="cancel-point">
                  <div className="">
                    <Card>
                      <CardBody className="cancel-cls">
                        <p>
                          <img src={cancel} alt="cancel" />
                        </p>
                        <h2>Escrow not found.</h2>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Row>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ User }) => ({
  user: User.user,
});

const mapDispatchToProps = {
  escrowStatusUpdateRequest
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EscrowDetails));
