import React, { Component } from "react";
import { Row, Col, Container, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import arrow from "../../../assets/images/arrow.png";

class CashToCrypto extends Component {
constructor(props) {
  super(props);
  this.state = {
    bankName: "Yourbankname",
    accountName: "Resonance123",
    accountNumber: "HABB092334739482832",
  };
}

handleChange = (event, fieldName) => {
  this.setState({ [fieldName]: event.target.value });
};

render() {
  return (
    <React.Fragment>
      <div className="page-freeze">
        <Container>
          <div className="crypto-content">
            <h1 className="crypto-heading m-0">
              <Link to="#" className="text-muted">
                <img src={arrow} alt="Arrow" />
              </Link>
              <span className="mx-2">CASH TO CRYPTO</span>
              <i className="mdi mdi-dots-horizontal"></i>
            </h1>
            <Row>
              <Col lg={12} className="history-d">
                <p>Bank Account to send cash in your wallet</p>
              </Col>
            </Row>
            <Row className="crypto-input">
              <Col lg={6}>
                <label className="form-label">BANK</label>
              </Col>
              <Col lg={6}>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.bankName}
                  onChange={(event) => this.handleChange(event, "bankName")}
                />
              </Col>
              <hr/>
              <Col lg={6}>
                <label className="form-label">Account Name</label>
              </Col>
              <Col lg={6}>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.accountName}
                  onChange={(event) => this.handleChange(event, "accountName")}
                />
              </Col>
              <hr/>
              <Col lg={6}>
                <label className="form-label">Account Number</label>
              </Col>
              <Col lg={6}>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.accountNumber}
                  onChange={(event) => this.handleChange(event, "accountNumber")}
                />
              </Col>
            </Row>
              <div className="shipment">
                <p>
                Please send your payment using a service with tracking number, 
                  so you can track the shipment. Resonance does not accept 
                  responsibility for lost shipments.
                  <b>Cost of cash to crypto is 12%.</b>
                </p>
              </div>
              <Row>
                <Col lg={12}>
                  <div className="detail-form">
                    <label className="form-label-cls">Tracking number</label>
                    <input
                      type="text"
                      className="form-control form-text-cls"
                      placeholder="e.g. 32326356724673"
                      defaultValue="TrackingNumber"
                    />
                  </div>
                  <div className="detail-form">
                    <label className="form-label-cls">Bank receipt</label>
                    <div className="custom-file">
                      <div className="file-name mr-auto form-control form-text-cls">
                        PDF/JPG/PNG
                      </div>
                      <label className="custom-file-label" htmlFor="attachment">
                        Upload File
                        <input
                          type="file"
                          className="custom-file-input"
                          id="attachment"
                          accept=".pdf,.jpg,.png"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="note-cash">
                    <span className="note-cls">Please note: </span>
                    <span className="cash-in">We accept cash only in USD, EUR, CHF.</span>
                  </div>
                  <Button type="button" className="d-flex mx-auto btn cryto-btn mt-5">
                    SEND MAIL
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CashToCrypto;